<template>
  <main>
    <div class="container">
      <h1>Список пользователей</h1>
      <ThisComponent></ThisComponent>
      <br>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminUsersList'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-users-list')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style scoped>
::v-deep .users-list__control {
  background-color: #222;
}
::v-deep .users-list__control span {
  color: #fff;
}
::v-deep .users-list__item:hover {
  background-color: #333;
}
</style>
